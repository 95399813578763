import AddIcon from '@mui/icons-material/Add'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import BadgeIcon from '@mui/icons-material/Badge'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import DiscountIcon from '@mui/icons-material/Discount'
import EastIcon from '@mui/icons-material/East'
import FaceIcon from '@mui/icons-material/Face'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import InventoryIcon from '@mui/icons-material/Inventory'
import ListIcon from '@mui/icons-material/List'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ManageHistoryIcon from '@mui/icons-material/ManageHistory'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import SettingsIcon from '@mui/icons-material/Settings'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import StorefrontIcon from '@mui/icons-material/Storefront'
import WestIcon from '@mui/icons-material/West'
import { AccountInfo } from '../../context/account_context'
import { ConfigsInfo, SystemBranchEnum } from '../../context/configs_context'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ConstructionIcon from '@mui/icons-material/Construction'
import MoneyIcon from '@mui/icons-material/Money'
import ListAltIcon from '@mui/icons-material/ListAlt'

export interface Navigation {
    text: string
    validate(account: AccountInfo, configs: ConfigsInfo): boolean
    icon?: JSX.Element
    link?: string
    subitems?: Navigation[]
    reducedText?: string
    description?: string
    hide?: boolean
}

export const navMenu: Navigation[] = [
    {
        text: 'Productos',
        icon: <InventoryIcon />,
        description: 'Gerencie os seus produtos',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'produtos'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista productos',
                link: '/system/product',
                icon: <ListIcon />,
                description: 'Liste os seus produtos',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'produtos' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo producto',
                link: '/system/product/new',
                icon: <AddIcon />,
                description: 'Adicione um novo produto',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'produtos' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar producto',
                link: '/system/product/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'moedas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1
                    )
                },
                hide: true,
            },
            {
                text: 'Descuentos',
                link: '/system/product/discount',
                icon: <DiscountIcon />,
                description: 'Gerencie os seus descontos',
                reducedText: 'Descuentos',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'produtos' &&
                                r.name.toLowerCase() === 'listar descontos'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo Descuento',
                link: '/system/product/discount/new',
                icon: <AddIcon />,
                description: 'Crie os seus descontos',
                reducedText: 'Nuevo Descuento',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'produtos' &&
                                r.name.toLowerCase() === 'criar desconto'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Cargar stock',
                link: '/system/product/load-stock',
                icon: <ArrowUpwardIcon />,
                description: 'Listar cargas de estoque',
                reducedText: 'Cargar stock',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'produtos' &&
                                r.name.toLowerCase() === 'listar descontos'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'carregar estoque'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nueva carga de stock',
                link: '/system/product/load-stock/new',
                description: 'Carregar estoque diretamente',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'produtos' &&
                                r.name.toLowerCase() === 'listar descontos'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'carregar estoque'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Transferir stock',
                link: '/system/product/transfer-stock',
                icon: <CompareArrowsIcon />,
                description: 'Listar transferencias de stock',
                reducedText: 'Transferir stock',
                validate: (account, configs) => {
                    return (
                        (account.isMaster ||
                            (account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar descontos'
                            ) !== -1 &&
                                account.rules.findIndex(
                                    (r) =>
                                        r.group.toLowerCase() === 'moedas' &&
                                        r.name.toLowerCase() === 'listar'
                                ) !== -1 &&
                                account.rules.findIndex(
                                    (r) =>
                                        r.group.toLowerCase() === 'produtos' &&
                                        r.name.toLowerCase() ===
                                            'transferir estoque'
                                ) !== -1)) &&
                        configs.systemBranch === SystemBranchEnum.Branch
                    )
                },
            },
            {
                text: 'Nueva transferencia de stock',
                link: '/system/product/transfer-stock/new',
                description: 'Transferir stock entre sucursales',
                reducedText: 'Nuevo',
                validate: (account, configs) => {
                    return (
                        (account.isMaster ||
                            (account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar descontos'
                            ) !== -1 &&
                                account.rules.findIndex(
                                    (r) =>
                                        r.group.toLowerCase() === 'moedas' &&
                                        r.name.toLowerCase() === 'listar'
                                ) !== -1 &&
                                account.rules.findIndex(
                                    (r) =>
                                        r.group.toLowerCase() === 'produtos' &&
                                        r.name.toLowerCase() ===
                                            'transferir estoque'
                                ) !== -1)) &&
                        configs.systemBranch === SystemBranchEnum.Branch
                    )
                },
                hide: true,
            },
            {
                text: 'Receitas',
                link: '/system/product/receipt',
                icon: <ReceiptIcon />,
                reducedText: 'Receitas',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'receitas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nueva receita',
                link: '/system/product/receipt/new',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'receitas' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Editar receita',
                link: '/system/product/receipt/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'receitas' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Fabricaciónes',
                link: '/system/product/phabrication',
                icon: <ConstructionIcon />,
                reducedText: 'Fabricaciónes',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'fabricação' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'receitas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nueva fabricación',
                link: '/system/product/phabrication/new',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'fabricação' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'receitas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Clientes',
        icon: <FaceIcon />,
        description: 'Gerencie os seus clientes',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'clientes'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista clientes',
                link: '/system/client',
                icon: <ListIcon />,
                description: 'Liste os seus clientes',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'clientes' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo cliente',
                link: '/system/client/new',
                icon: <AddIcon />,
                description: 'Adicione um novo cliente',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'clientes' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar cliente',
                link: '/system/client/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'clientes' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Proveedor',
        icon: <LocalShippingIcon />,
        description: 'Gerencie os seus fornecedores',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'fornecedores'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista proveedores',
                link: '/system/supplier',
                icon: <ListIcon />,
                description: 'Liste os seus fornecedores',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'fornecedores' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo proveedor',
                link: '/system/supplier/new',
                icon: <AddIcon />,
                description: 'Adicione um novo fornecedor',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'fornecedores' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar proveedor',
                link: '/system/supplier/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'fornecedores' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Compras',
        icon: <WestIcon />,
        description: 'Gerencie as suas compras',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'compras'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'fornecedores' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'produtos' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista compras',
                link: '/system/buy',
                icon: <ListIcon />,
                description: 'Liste as suas compras',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'compras' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'fornecedores' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nueva compra',
                link: '/system/buy/new',
                icon: <AddIcon />,
                description: 'Adicione uma nova compra',
                reducedText: 'Nueva',
                validate: (account, configs) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'compras' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'fornecedores' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar compra',
                link: '/system/buy/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'compras' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'fornecedores' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Productos comprados',
                link: '/system/buy/buyed-products',
                icon: <ArrowUpwardIcon />,
                description: 'Listagem de produtos comprados',
                reducedText: 'Productos comprados',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'compras' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'compras' &&
                                    r.name.toLowerCase() === 'dados gerais'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'fornecedores' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
        ],
    },
    {
        text: 'Ventas',
        icon: <EastIcon />,
        description: 'Gerencie as suas vendas',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'vendas'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'clientes' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'produtos' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'métodos de pagamento' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista ventas',
                link: '/system/sale',
                icon: <ListIcon />,
                description: 'Liste as suas vendas',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nueva venta',
                link: '/system/sale/new',
                icon: <AddIcon />,
                description: 'Adicione uma nova venda',
                reducedText: 'Nueva',
                validate: (account, configs) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar venta',
                link: '/system/sale/edit',
                description: 'Editar uma nova venda',
                reducedText: 'Editar',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'clientes' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Productos vendidos',
                link: '/system/sale/sold-products',
                icon: <ArrowUpwardIcon />,
                description: 'Listagem de produtos vendidos',
                reducedText: 'Productos vendidos',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'vendas' &&
                                    r.name.toLowerCase() === 'dados gerais'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Orçamentos',
                link: '/system/sale/budget',
                icon: <ListAltIcon />,
                description: 'Listagem de orçamentos',
                reducedText: 'Orçamentos',
                validate: (account, configs) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'listar orçamentos'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo orçamento',
                link: '/system/sale/budget/new',
                icon: <AddIcon />,
                description: 'Adicione um novo orçamento',
                reducedText: 'Nuevo orçamento',
                validate: (account, configs) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'criar orçamento'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Editar orçamento',
                link: '/system/sale/budget/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'atualizar orçamento'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
            {
                text: 'Pagamentos',
                link: '/system/sale/payment-sale',
                icon: <MoneyIcon />,
                description: 'Liste os seus pagamentos',
                reducedText: 'Pagamentos',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'vendas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() ===
                                        'métodos de pagamento' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
        ],
    },
    {
        text: 'Pagos',
        icon: <CreditCardIcon />,
        description: 'Gerencie os seus métodos de pagamento',
        validate: (account) => {
            return (
                account.isMaster ||
                account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'métodos de pagamento'
                ) !== -1
            )
        },
        subitems: [
            {
                text: 'Lista pagos',
                link: '/system/payment-method',
                icon: <ListIcon />,
                description: 'Liste as suas vendas',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() ===
                                    'métodos de pagamento' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Nuevo pago',
                link: '/system/payment-method/new',
                icon: <AddIcon />,
                description: 'Adicione uma novo método de pagamento',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() ===
                                    'métodos de pagamento' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Editar método de pagamento',
                link: '/system/payment-method/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() ===
                                    'métodos de pagamento' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Notas',
        icon: <ReceiptLongIcon />,
        description: 'Gerencie as suas notas',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'notas'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'produtos' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista notas',
                link: '/system/note',
                icon: <ListIcon />,
                description: 'Liste as suas notas',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'notas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nueva nota',
                link: '/system/note/new',
                icon: <AddIcon />,
                description: 'Crie una nota',
                reducedText: 'Nueva',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'notas' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar nota',
                link: '/system/note/edit',
                description: 'Edite una nota',
                reducedText: 'Editar',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'notas' &&
                                r.name.toLowerCase() === 'editar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'produtos' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Gastos',
        icon: <ShoppingCartIcon />,
        description: 'Gerencie os seus gastos',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'gastos'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista gastos',
                link: '/system/spent',
                icon: <ListIcon />,
                description: 'Liste os seus gastos',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'gastos' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo gasto',
                link: '/system/spent/new',
                icon: <AddIcon />,
                description: 'Adicione um novo gasto',
                reducedText: 'Nuevo',
                validate: (account, configs) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'gastos' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
        ],
    },
    {
        text: 'Servicios',
        icon: <HomeRepairServiceIcon />,
        description: 'Gestiona tus servicios',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'serviços'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'clientes' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Lista servicios',
                link: '/system/service',
                icon: <ListIcon />,
                description: 'Enumere sus servicios',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'serviços' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo servicio',
                link: '/system/service/new',
                icon: <AddIcon />,
                description: 'Agregar un nuevo servicio',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'serviços' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Edita servicio',
                link: '/system/service/edit',
                description: 'Editar un servicio',
                reducedText: 'Edita',
                hide: true,
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'serviços' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'clientes' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
        ],
    },
    {
        text: 'Monedas',
        icon: <CurrencyExchangeIcon />,
        description: 'Gerencie as suas moedas',
        validate: (account) => {
            return (
                account.isMaster ||
                account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'moedas'
                ) !== -1
            )
        },
        subitems: [
            {
                text: 'Lista monedas',
                link: '/system/coin',
                icon: <ListIcon />,
                description: 'Liste as suas moedas',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'moedas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Nueva moneda',
                link: '/system/coin/new',
                icon: <AddIcon />,
                description: 'Adicione uma nova moeda',
                reducedText: 'Nueva',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'moedas' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Editar moneda',
                link: '/system/coin/edit',
                description: 'Editar una moneda',
                reducedText: 'Editar',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'moedas' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Funcionários',
        icon: <BadgeIcon />,
        description: 'Gerencie os seus funcionários',
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'funcionários'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Gerenciar funcionários',
                link: '/system/employee/manage',
                icon: <ManageHistoryIcon />,
                description: 'Gerencie os seus funcionários',
                reducedText: 'Gerenciar',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'funcionários' &&
                                r.name.toLowerCase() === 'gerenciar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Lista funcionários',
                link: '/system/employee',
                icon: <ListIcon />,
                description: 'Liste os seus funcionários',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'funcionários' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo funcionário',
                link: '/system/employee/new',
                icon: <AddIcon />,
                description: 'Adicione um novo funcionário',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'funcionários' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar funcionário',
                link: '/system/employee/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'funcionários' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Documentos',
        icon: <PictureAsPdfIcon />,
        description: 'Gerencie os seus documentos',
        validate: (account) => {
            return (
                account.isMaster ||
                account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'documentos'
                ) !== -1
            )
        },
        subitems: [
            {
                text: 'Lista documentos',
                link: '/system/document',
                icon: <ListIcon />,
                description: 'Liste os seus documentos',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'documentos' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1
                    )
                },
            },
        ],
    },
    {
        text: 'Cuentas',
        icon: <ManageAccountsIcon />,
        description: 'Gerencie as suas contas',
        validate: (account) => {
            return (
                account.isMaster ||
                account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'contas'
                ) !== -1
            )
        },
        subitems: [
            {
                text: 'Lista cuentas',
                link: '/system/account',
                icon: <ListIcon />,
                description: 'Liste as suas contas',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'contas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Nueva cuenta',
                link: '/system/account/new',
                icon: <AddIcon />,
                description: 'Adicione uma nova conta',
                reducedText: 'Nueva',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'contas' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Editar cuenta',
                link: '/system/account/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'contas' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Caja',
        description: 'Gerencie os caixas',
        icon: <ManageHistoryIcon />,
        validate: (account) => {
            return (
                account.isMaster ||
                (account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'caixas'
                ) !== -1 &&
                    account.rules.findIndex(
                        (r) =>
                            r.group.toLowerCase() === 'moedas' &&
                            r.name.toLowerCase() === 'listar'
                    ) !== -1)
            )
        },
        subitems: [
            {
                text: 'Administar cajas',
                link: '/system/cashier/manage',
                icon: <ManageHistoryIcon />,
                description:
                    'Gerencie a abertura/fechamento e histórico do caixa',
                reducedText: 'Administar',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'caixas' &&
                                r.name.toLowerCase() === 'gerenciar caixas'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Lista cajas',
                link: '/system/cashier',
                icon: <ListIcon />,
                description: 'Liste os seus caixas',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'caixas' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Nuevo caja',
                link: '/system/cashier/new',
                icon: <AddIcon />,
                description: 'Adicione um novo caixa',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'caixas' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
            },
            {
                text: 'Editar caixa',
                link: '/system/cashier/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        (account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'caixas' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1 &&
                            account.rules.findIndex(
                                (r) =>
                                    r.group.toLowerCase() === 'moedas' &&
                                    r.name.toLowerCase() === 'listar'
                            ) !== -1)
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Vehículo',
        description: 'Gerencie los vehículos',
        icon: <DirectionsCarFilledIcon />,
        validate: (account) => {
            return (
                account.isMaster ||
                account.rules.findIndex(
                    (r) => r.group.toLowerCase() === 'veículos'
                ) !== -1
            )
        },
        subitems: [
            {
                text: 'Lista vehículos',
                link: '/system/vehicle',
                icon: <ListIcon />,
                description: 'Liste los seus vehículos',
                reducedText: 'Lista',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'veículos' &&
                                r.name.toLowerCase() === 'listar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Nuevo vehículo',
                link: '/system/vehicle/new',
                icon: <AddIcon />,
                description: 'Adicione um nuevo vehículo',
                reducedText: 'Nuevo',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'veículos' &&
                                r.name.toLowerCase() === 'criar'
                        ) !== -1
                    )
                },
            },
            {
                text: 'Editar caixa',
                link: '/system/vehicle/edit',
                validate: (account) => {
                    return (
                        account.isMaster ||
                        account.rules.findIndex(
                            (r) =>
                                r.group.toLowerCase() === 'veículos' &&
                                r.name.toLowerCase() === 'atualizar'
                        ) !== -1
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Painel admin',
        link: '/system/dashboard',
        description: 'Visualize o painel administrativo',
        icon: <DashboardIcon />,
        validate: (account) => {
            return (
                account.isMaster ||
                account.rules.findIndex(
                    (r) =>
                        r.group.toLowerCase() === 'painel' &&
                        r.name.toLowerCase() === 'visualizar'
                ) !== -1
            )
        },
    },
    {
        text: 'Sucursales',
        icon: <StorefrontIcon />,
        description: 'Gerencie as suas filiais',
        validate: (account, configs) => {
            return (
                account.isMaster &&
                configs.systemBranch === SystemBranchEnum.Branch
            )
        },
        subitems: [
            {
                text: 'Lista sucursales',
                link: '/system/branch',
                icon: <ListIcon />,
                description: 'Liste as suas filiais',
                reducedText: 'Lista',
                validate: (account, configs) => {
                    return (
                        account.isMaster &&
                        configs.systemBranch === SystemBranchEnum.Branch
                    )
                },
            },
            {
                text: 'Nueva sucursal',
                link: '/system/branch/new',
                icon: <AddIcon />,
                description: 'Adicione uma nova filial',
                reducedText: 'Nueva',
                validate: (account, configs) => {
                    return (
                        account.isMaster &&
                        configs.systemBranch === SystemBranchEnum.Branch
                    )
                },
            },
            {
                text: 'Editar sucursal',
                link: '/system/branch/edit',
                validate: (account, configs) => {
                    return (
                        account.isMaster &&
                        configs.systemBranch === SystemBranchEnum.Branch
                    )
                },
                hide: true,
            },
        ],
    },
    {
        text: 'Configs',
        link: '/system/configs',
        description: 'Gerencie as configurações do sistema',
        icon: <SettingsIcon />,
        validate: (account) => {
            return account.isMaster
        },
    },
]

function getFlatMenu(): Navigation[] {
    const newNav: Navigation[] = []
    for (const nav of navMenu) {
        newNav.push(nav)
        if (nav.subitems)
            for (const subNav of nav.subitems) {
                newNav.push(subNav)
            }
    }

    return newNav
}

export const flatNavMenu = getFlatMenu()
