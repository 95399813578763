import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoading } from './loading_context'
import { useSnack } from './snack_context'
import {
    createNote,
    findActiveNote,
    findNoteById,
    updateNote,
} from '../services/note'
import { BranchInfo } from './branch_context'
import { SystemBranchEnum, useConfigs } from './configs_context'
import { useAccount } from './account_context'
import { getDateInIsoFormat } from '../utils/maks'

export interface NoteInfo {
    id: number
    totalQnt: number
    usedQnt: number
    initialNote: number
    iniD: Date
    endD: Date | null
    timbrado: number
    prefix: string
    isActive: boolean
    isEletronicNote: boolean
    isSale: boolean
    isCreditNote: boolean
    isTransferStock: boolean
    isReceipt: boolean
    branches?: BranchInfo[]
}

export interface CraeteNoteInfo {
    id?: number
    totalQnt: number | null
    iniD: Date | null
    endD: Date | null
    timbrado: number
    prefix: string
    isActive: boolean
    isEletronicNote: boolean
    isSale: boolean
    isCreditNote: boolean
    isTransferStock: boolean
    isReceipt: boolean
    branches?: (BranchInfo | null)[]
    initialNote: number
    isEditing?: boolean
}

export interface NoteError {
    totalQnt: string
    iniD: string
    endD: string
    timbrado: string
    prefix: string
    initialNote: string
    branches: string[]
}

const createNoteInfoDefault: CraeteNoteInfo = {
    totalQnt: null,
    iniD: null,
    endD: null,
    timbrado: 0,
    prefix: '',
    isActive: true,
    initialNote: 0,
    branches: [null],
    isEletronicNote: false,
    isSale: false,
    isCreditNote: false,
    isTransferStock: false,
    isReceipt: false,
    isEditing: false,
}

const noteErrorDefault: NoteError = {
    totalQnt: '',
    iniD: '',
    endD: '',
    timbrado: '',
    prefix: '',
    initialNote: '',
    branches: [''],
}

export interface NoteContextInterface {
    activeNote: NoteInfo | null
    handleFindActiveNote(branchId?: number): Promise<void>
    createNoteInfo: CraeteNoteInfo
    setCreateNoteInfo: Dispatch<SetStateAction<CraeteNoteInfo>>
    noteError: NoteError
    setNoteError: Dispatch<SetStateAction<NoteError>>
    validate(): boolean
    handleCreateNewNote(): Promise<void>
    startNewNote(): void
    editNote(id: number): Promise<void>
}

const noteContextDefault: NoteContextInterface = {
    activeNote: null,
    handleFindActiveNote: () => Promise.resolve(),
    createNoteInfo: createNoteInfoDefault,
    setCreateNoteInfo: () => {},
    noteError: noteErrorDefault,
    setNoteError: () => {},
    validate: () => false,
    handleCreateNewNote: () => Promise.resolve(),
    startNewNote: () => {},
    editNote: () => Promise.resolve(),
}

const NoteContext = createContext<NoteContextInterface>(noteContextDefault)

export function useNote() {
    return useContext(NoteContext)
}

interface Props {
    children: ReactNode
}

export function NoteContextProvider({ children }: Props) {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const [activeNote, setActiveNote] = useState<NoteInfo | null>(null)
    const [createNoteInfo, setCreateNoteInfo] = useState<CraeteNoteInfo>(
        createNoteInfoDefault
    )
    const [noteError, setNoteError] = useState<NoteError>(noteErrorDefault)
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    const handleFindActiveNote = async (branchId?: number) => {
        loading.logicShow()
        try {
            const activeNote = await findActiveNote(branchId)
            setActiveNote(activeNote)
        } catch (error) {
            setActiveNote(null)
            // snack.error('Nenhuma nota ativa encontrada')
        }
        loading.logicHide()
    }

    const validate = (): boolean => {
        const isMasterBranch =
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch

        let isOk = true
        const newNoteError = JSON.parse(JSON.stringify(noteErrorDefault))

        if (!createNoteInfo.initialNote) {
            newNoteError.initialNote = 'Campo obrigatório'
            isOk = false
        }
        if (!createNoteInfo.totalQnt && !createNoteInfo.isEletronicNote) {
            newNoteError.totalQnt = 'Campo obrigatório'
            isOk = false
        }
        if (!createNoteInfo.iniD) {
            newNoteError.iniD = 'Campo obrigatório'
            isOk = false
        }
        if (!createNoteInfo.endD && !createNoteInfo.isEletronicNote) {
            newNoteError.endD = 'Campo obrigatório'
            isOk = false
        }
        if (!createNoteInfo.timbrado) {
            newNoteError.timbrado = 'Campo obrigatório'
            isOk = false
        }
        if (!createNoteInfo.prefix) {
            newNoteError.prefix = 'Campo obrigatório'
            isOk = false
        } else if (createNoteInfo.prefix.length !== 7) {
            newNoteError.prefix = 'Valor inválido'
            isOk = false
        }
        if (isMasterBranch) {
            if (!createNoteInfo.branches) {
                isOk = false
                snack.error('Selecione ao menos una sucursal!')
            } else {
                for (let i = 0; i < createNoteInfo.branches.length; i++) {
                    if (!createNoteInfo.branches[i]) {
                        newNoteError.branches[i] = 'Campo obrigatório'
                        isOk = false
                    }
                }
            }
        }

        setNoteError(newNoteError)
        return isOk
    }

    const handleCreateNewNote = async () => {
        const isMasterBranch =
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch

        loading.show()
        try {
            if (!createNoteInfo.isEditing)
                await createNote({
                    qnt_total: createNoteInfo.totalQnt,
                    data_inicial: createNoteInfo.iniD
                        ? getDateInIsoFormat(createNoteInfo.iniD)
                        : '',
                    data_final: createNoteInfo.endD
                        ? getDateInIsoFormat(createNoteInfo.endD)
                        : null,
                    timbrado: createNoteInfo.timbrado,
                    prefixo_nota: createNoteInfo.prefix,
                    flag_ativo: Number(createNoteInfo.isActive),
                    flag_fatura_eletronica: Number(
                        createNoteInfo.isEletronicNote
                    ),
                    flag_venda: Number(createNoteInfo.isSale),
                    flag_nota_credito: Number(createNoteInfo.isCreditNote),
                    flag_transferencia_estoque: Number(
                        createNoteInfo.isTransferStock
                    ),
                    flag_recibo: Number(createNoteInfo.isReceipt),
                    id_filial: isMasterBranch
                        ? createNoteInfo.branches?.map((b) => b?.id ?? 0)
                        : undefined,
                    nota_inicial: createNoteInfo.initialNote,
                })
            else
                await updateNote({
                    id_nota: createNoteInfo.id as number,
                    qnt_total: createNoteInfo.totalQnt,
                    data_inicial: createNoteInfo.iniD
                        ? getDateInIsoFormat(createNoteInfo.iniD)
                        : '',
                    data_final: createNoteInfo.endD
                        ? getDateInIsoFormat(createNoteInfo.endD)
                        : null,
                    timbrado: createNoteInfo.timbrado,
                    prefixo_nota: createNoteInfo.prefix,
                    flag_ativo: Number(createNoteInfo.isActive),
                    flag_fatura_eletronica: Number(
                        createNoteInfo.isEletronicNote
                    ),
                    flag_venda: Number(createNoteInfo.isSale),
                    flag_nota_credito: Number(createNoteInfo.isCreditNote),
                    flag_transferencia_estoque: Number(
                        createNoteInfo.isTransferStock
                    ),
                    flag_recibo: Number(createNoteInfo.isReceipt),
                    id_filial: isMasterBranch
                        ? createNoteInfo.branches?.map((b) => b?.id ?? 0)
                        : undefined,
                })
            setCreateNoteInfo({ ...createNoteInfoDefault })
            navigate('/system/note')
            snack.success(
                `Nota ${
                    !createNoteInfo.isEditing ? 'cadastrada' : 'atualizada'
                } com sucesso`
            )
        } catch (error) {
            snack.connectionFail()
        }
        loading.hide()
    }

    const startNewNote = () => {
        setCreateNoteInfo({ ...createNoteInfoDefault })
        setNoteError({ ...noteErrorDefault })
    }

    const editNote = async (id: number) => {
        try {
            const note = await findNoteById(id)
            setCreateNoteInfo({ ...note, isEditing: true })
            setNoteError({ ...noteErrorDefault })
        } catch (error) {
            snack.connectionFail()
            throw new Error('Note not found')
        }
    }

    return (
        <NoteContext.Provider
            value={{
                activeNote,
                handleFindActiveNote,
                createNoteInfo,
                setCreateNoteInfo,
                noteError,
                setNoteError,
                validate,
                handleCreateNewNote,
                startNewNote,
                editNote,
            }}
        >
            {children}
        </NoteContext.Provider>
    )
}
