import { NoteInfo } from '../context/notes_context'
import { BranchApiData, parseBranch } from './branch'
import { get, post, put } from './generic'

export interface NoteApiData {
    id_nota: number
    qnt_total: number
    qnt_utilizada: number
    nota_inicial: number
    data_inicial: string
    data_final: string
    timbrado: string
    prefixo_nota: string
    flag_ativo: number
    flag_fatura_eletronica: number
    flag_venda: number
    flag_nota_credito: number
    flag_transferencia_estoque: number
    flag_recibo: number
    notaFiliais?: {
        filial: BranchApiData
    }[]
}

interface CreateNoteInterface {
    qnt_total: number | null
    data_inicial: string
    data_final: string | null
    timbrado: number
    prefixo_nota: string
    flag_ativo: number
    flag_fatura_eletronica: number
    flag_venda: number
    flag_nota_credito: number
    flag_transferencia_estoque: number
    flag_recibo: number
    id_filial?: number[]
    nota_inicial?: number | null
}

interface UpdateNoteInterface extends Partial<CreateNoteInterface> {
    id_nota: number
}

export function parseNote(data: NoteApiData): NoteInfo {
    return {
        id: data.id_nota,
        totalQnt: data.qnt_total,
        usedQnt: data.qnt_utilizada,
        initialNote: data.nota_inicial,
        iniD: new Date(`${data.data_inicial} 00:00:00`),
        endD: data.data_final ? new Date(`${data.data_final} 00:00:00`) : null,
        timbrado: Number(data.timbrado),
        prefix: data.prefixo_nota,
        isActive: Boolean(data.flag_ativo),
        isEletronicNote: Boolean(data.flag_fatura_eletronica),
        isSale: Boolean(data.flag_venda),
        isCreditNote: Boolean(data.flag_nota_credito),
        isTransferStock: Boolean(data.flag_transferencia_estoque),
        isReceipt: Boolean(data.flag_recibo),
        branches: data.notaFiliais
            ? data.notaFiliais.map((notaFilial) =>
                  parseBranch(notaFilial.filial)
              )
            : undefined,
    }
}

export async function createNote(data: CreateNoteInterface): Promise<void> {
    await post('note', data, {
        useRecaptcha: true,
    })
}

export async function updateNote(data: UpdateNoteInterface): Promise<void> {
    await put('note', data, {
        useRecaptcha: true,
    })
}

export async function listNotes(): Promise<NoteInfo[]> {
    const res = await get('note')
    return res.data.data.map((data: NoteApiData) => parseNote(data))
}

export async function findActiveNote(branchId?: number): Promise<NoteInfo> {
    const res = await post('note/active', {
        id_filial_master: branchId,
    })
    return parseNote(res.data.data)
}

export async function findNoteById(id: number): Promise<NoteInfo> {
    const res = await get(`note/${id}`)
    return parseNote(res.data.data)
}
