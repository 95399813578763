import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { CoinExchangeType, CoinInfo, useCoin } from './coin_context'
import { IvaEnum, ProductInfo } from './product_context'
import { useSnack } from './snack_context'
import { SupplierInfo } from './supplier_context'
import { useLoading } from './loading_context'
import { createBuy, getBuyById, updateBuy } from '../services/buy'
import { useNavigate } from 'react-router-dom'
import { AccountInfo, useAccount } from './account_context'
import { CashierClosedBuyError } from '../services/buy'
import { getDateInIsoFormat, getExchangeValue } from '../utils/maks'
import { CashierInfo } from './cashier_context'
import { loadStock } from '../services/product'
import { BranchInfo } from './branch_context'
import { SystemBranchEnum, useConfigs } from './configs_context'
import { PaymentMethodInfo } from './payment_method_context'

export interface ExchangeInfo {
    coin: CoinInfo
    exchange: number
    amount: number
    flagReverseExchange: number
}

export interface ProductInBuy {
    id?: number
    product: ProductInfo
    price: number
    porcentGain: number
    salePrice: number
    exchange: ExchangeInfo | null
    qnt: number
    subtotal: number
    negativeQnt?: number
    returnedQnt?: number
}

export interface PaymentInBuy {
    id?: number
    paymentCoin: CoinInfo | null
    changeCoin: CoinInfo | null
    amountPayed: number
    paymentMethod: PaymentMethodInfo | null
    madeBy: AccountInfo | null
    date?: string
    hour?: string
    cashier?: CashierInfo
    exchange?: {
        exchangeValue: number
        exchangeAmountPayed: number
        flagReverseExchange: number
    }
    proofText?: string
    proofImage?: string
    isSummerTime?: boolean
    exchangeChange: number | null
    change: number
    paymentStatus?: number
    cashierDiscounted: boolean
}

export enum PaymentType {
    Normal = 0,
    Credit = 1,
    Parcel = 2,
}

export enum BuyStatus {
    Open = 0,
    Closed = 1,
    Canceled = 2,
}

export enum BuyPaymentStatus {
    Pending = 0,
    Created = 1,
    Canceled = 2,
}

export const BuyStatusLabel: Record<number, string> = {
    0: 'Abierta',
    1: 'Cerrada',
    2: 'Cancelada',
}

export const BuyPaymentStatusLabel: Record<number, string> = {
    0: 'Pendiente',
    1: 'Cerrada',
    2: 'Cancelada',
}

export const ConsignationStatusLabel: Record<number, string> = {
    0: 'Sin consigniación',
    1: 'Consigniación pendiente',
    2: 'Consigniación finalizada',
}

export enum ConsignationStatus {
    NoConsignation = 0,
    PendingConsignation = 1,
    EndedConsignation = 2,
}

export interface BuyInfo {
    id?: number
    bill: string
    products: ProductInBuy[]
    selectedProducts?: ProductInBuy[]
    supplier: SupplierInfo | null
    total: number
    totalAmountPayed: number
    totalIva: number
    paymentType: number
    payments: PaymentInBuy[]
    branch: BranchInfo | null
    date?: string
    hour?: string
    coin?: CoinInfo
    cashier?: CashierInfo
    account?: AccountInfo
    productCount?: number
    isSummerTime?: boolean
    paymentStatus?: number
    dispatch: number
    freight: number
    discharge: number
    manualTotal: number
    consignationDate: Date | null
    consignationStatus?: number
    isEditing?: boolean
}

export interface BuyReportsInfo {
    coinId: number
    totalBuyed: number
    totalPaid: number
    totalBuys: number
    coin?: CoinInfo
}

export interface BuyedProductsInfo {
    productId: number
    product: ProductInfo
    averageBuyPrice: number
    qntBuyed: number
    qntBuys: number
    coin?: CoinInfo
}

export interface ProductInConsignation {
    id: number
    qnt: number
    productInBuy: ProductInBuy
}

export interface ConsignationInfo {
    id?: number
    products: ProductInConsignation[]
    total: number
    date?: string
    hour?: string
    cashier?: Partial<CashierInfo>
    account?: Partial<AccountInfo>
    isSummerTime?: boolean
}

const buyInfoDefault: BuyInfo = {
    bill: '',
    products: [],
    supplier: null,
    total: 0,
    totalAmountPayed: 0,
    totalIva: 0,
    paymentType: 0,
    payments: [],
    branch: null,
    dispatch: 0,
    freight: 0,
    discharge: 0,
    manualTotal: 0,
    consignationDate: null,
    isEditing: false,
}

export const getSupplierLabel = (supplier: SupplierInfo) => {
    return `${supplier.name}, email: ${supplier.email}, documento: ${supplier.document}`
}

export interface BuyContextInterface {
    buyInfo: BuyInfo
    setBuyInfo: Dispatch<SetStateAction<BuyInfo>>
    handleChangeBill(bill: string): void
    handleAddNewProducts(products: ProductInfo[]): void
    handleRemoveProduct(product: ProductInBuy): void
    handleChangeProductQnt(product: ProductInBuy, qnt: number): void
    handleChangeProductNegativeQnt(product: ProductInBuy, qnt: number): void
    handleChangeProductPrice(product: ProductInBuy, price: number): void
    handleChangeProductSubtotal(product: ProductInBuy, subtotal: number): void
    handleChangeProductExchange(
        product: ProductInBuy,
        exchange: ExchangeInfo
    ): void
    handleEditProduct(productInBuy: ProductInBuy, product: ProductInfo): void
    handleChangeSupplier(supplier: SupplierInfo | null): void
    handleChangePayment(paymentType: number, payment: PaymentInBuy): void
    handleChangeBranch(branch: BranchInfo): void
    validate(): boolean
    handleCreateNewBuy(
        paymentType?: number,
        payment?: PaymentInBuy
    ): Promise<void>
    validateLoadStock(): boolean
    handleCreateNewLoadStock(): Promise<void>
    handleChangeSelectedProducts(products: ProductInBuy[]): void
    resetContext(): void
    editBuy(id: number): Promise<void>
    handleCalculatePriceWithCost(): void
}

const buyContext = createContext<BuyContextInterface>({
    buyInfo: buyInfoDefault,
    setBuyInfo: () => {},
    handleChangeBill: () => {},
    handleAddNewProducts: () => {},
    handleRemoveProduct: () => {},
    handleChangeProductQnt: () => {},
    handleChangeProductNegativeQnt: () => {},
    handleChangeProductPrice: () => {},
    handleChangeProductSubtotal: () => {},
    handleChangeProductExchange: () => {},
    handleEditProduct: () => {},
    handleChangeSupplier: () => {},
    handleChangePayment: () => {},
    handleChangeBranch: () => {},
    validate: () => false,
    handleCreateNewBuy: async () => {},
    validateLoadStock: () => false,
    handleCreateNewLoadStock: async () => {},
    handleChangeSelectedProducts: () => {},
    resetContext: () => {},
    editBuy: () => Promise.resolve(),
    handleCalculatePriceWithCost: () => {},
})

export function useBuy() {
    return useContext(buyContext)
}

interface Props {
    children: ReactNode
}

export const BuyContextProvider = ({ children }: Props) => {
    const loading = useLoading()
    const snack = useSnack()
    const navigate = useNavigate()
    const { coins } = useCoin()
    const [buyInfo, setBuyInfo] = useState<BuyInfo>(buyInfoDefault)
    const { accountInfo } = useAccount()
    const { configs } = useConfigs()

    const getTotal = (products: ProductInBuy[]) => {
        return products
            .map((p) => p.subtotal)
            .reduce((total, current) => {
                return total + current
            }, 0)
    }

    const getTotalIva = (products: ProductInBuy[]) => {
        return products.reduce((total, current) => {
            const iva =
                current.product.iva === IvaEnum.Exento
                    ? 0
                    : current.product.iva === IvaEnum.Cinco
                      ? 21
                      : 11
            if (!iva) return total
            return total + current.subtotal / iva
        }, 0)
    }

    const handleChangeBill = (bill: string) => {
        setBuyInfo((prev) => ({
            ...prev,
            bill: bill,
        }))
    }

    const handleAddNewProducts = (products: ProductInfo[]) => {
        const newProducts = [...buyInfo.products]
        let count = buyInfo.productCount ?? 0

        products.forEach((product) => {
            const foundProduct = buyInfo.products.find(
                (p) => p.product.id === product.id
            )
            if (foundProduct && foundProduct.product.autoSum) {
                handleChangeProductQnt(foundProduct, foundProduct.qnt + 1)
                return
            }
            if (!product.isAtive) {
                snack.error('O produto está desativado!')
                return
            }

            count += 1
            newProducts.push({
                product: product,
                price: product.buyPrice,
                exchange: null,
                qnt: 1,
                subtotal: product.buyPrice,
                salePrice: product.salePrice,
                porcentGain: product.porcentGain,
                id: count,
                negativeQnt: 0,
            })
        })

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
            productCount: count,
        }))
    }

    const handleRemoveProduct = (product: ProductInBuy) => {
        const newProducts = [...buyInfo.products]
        const index = newProducts.findIndex((p) => p.id === product.id)
        if (index === -1) return

        newProducts.splice(index, 1)

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductQnt = (product: ProductInBuy, qnt: number) => {
        const newProducts = [...buyInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        newProduct.qnt = qnt
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductNegativeQnt = (
        product: ProductInBuy,
        qnt: number
    ) => {
        const newProducts = [...buyInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        newProduct.negativeQnt = qnt

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
        }))
    }

    const handleChangeProductPrice = (product: ProductInBuy, price: number) => {
        const newProducts = [...buyInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        newProduct.price = price
        newProduct.salePrice = price + (price * newProduct.porcentGain) / 100
        newProduct.exchange = null
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductSubtotal = (
        product: ProductInBuy,
        subtotal: number
    ) => {
        const newProducts = [...buyInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        newProduct.subtotal = subtotal
        newProduct.price = newProduct.subtotal / newProduct.qnt
        newProduct.salePrice =
            newProduct.price + (newProduct.price * newProduct.porcentGain) / 100
        newProduct.exchange = null

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeProductExchange = (
        product: ProductInBuy,
        exchange: ExchangeInfo
    ) => {
        const newProducts = [...buyInfo.products]
        const newProduct = newProducts.find((p) => p.id === product.id)
        if (!newProduct) return

        newProduct.exchange = exchange
        newProduct.price = getExchangeValue({
            amount: newProduct.exchange.amount,
            exchange: newProduct.exchange.exchange,
            coinExchangeType: newProduct.exchange.coin.flagReverseExchange,
            order: 'reverse',
        })
        newProduct.subtotal = newProduct.qnt * newProduct.price

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleEditProduct = (
        productInBuy: ProductInBuy,
        product: ProductInfo
    ) => {
        const newProducts = [...buyInfo.products]
        const foundProduct = newProducts.find((p) => p.id === productInBuy.id)
        if (!foundProduct) return

        foundProduct.exchange = null
        foundProduct.price = product.buyPrice
        foundProduct.salePrice = product.salePrice
        foundProduct.porcentGain = product.porcentGain
        foundProduct.subtotal = foundProduct.price * foundProduct.qnt
        foundProduct.product = product

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    const handleChangeSupplier = (supplier: SupplierInfo | null) => {
        if (supplier && !supplier.isActive) {
            snack.error('O forncedor não está ativo!')
            return
        }

        setBuyInfo((prev) => ({
            ...prev,
            supplier: supplier,
        }))
    }

    const handleChangePayment = (
        paymentType: number,
        payment: PaymentInBuy
    ) => {
        setBuyInfo((prev) => ({
            ...prev,
            paymentType: paymentType,
            payments: [payment],
        }))
    }

    const handleChangeBranch = (branch: BranchInfo) => {
        setBuyInfo((prev) => ({
            ...prev,
            branch: branch,
        }))
    }

    const validate = (): boolean => {
        if (!buyInfo.supplier) {
            snack.error('Selecione um fornecedor')
            return false
        }
        if (!buyInfo.bill) {
            snack.error('Selecione uma fatura')
            return false
        }
        if (!buyInfo.products.length) {
            snack.error('Selecione um produto!')
            return false
        }
        if (
            buyInfo.products.filter((p) => !p.price || !p.salePrice || !p.qnt)
                .length
        ) {
            snack.error('A quantidade e o valor não podem ser 0!')
            return false
        }
        if (
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !buyInfo.branch
        ) {
            snack.error('Selecione uma filial!')
            return false
        }
        return true
    }

    const validateLoadStock = (): boolean => {
        if (!buyInfo.products.length) {
            snack.error('Selecione um produto!')
            return false
        }
        if (buyInfo.products.filter((p) => !p.qnt && !p.negativeQnt).length) {
            snack.error('O estoque não pode ser 0!')
            return false
        }
        if (
            !accountInfo?.branch &&
            configs.systemBranch === SystemBranchEnum.Branch &&
            !buyInfo.branch
        ) {
            snack.error('Selecione uma filial!')
            return false
        }
        return true
    }

    const handleCreateNewBuy = async (
        paymentType?: number,
        payment?: PaymentInBuy
    ) => {
        loading.show()
        try {
            if (buyInfo.isEditing)
                await updateBuy({
                    id_compra: buyInfo.id as number,
                    produtos: buyInfo.products.map((p) => ({
                        id_produto: p.product?.id ?? 0,
                        preco_compra: p.price,
                        preco_venda: p.salePrice,
                        quantidade_produto: p.qnt,
                        porc_lucro: p.porcentGain,
                        exchange: p.exchange
                            ? {
                                  id_moeda_estrangeira: p.exchange.coin.id ?? 0,
                                  valor_moeda_estrangeira: p.exchange.amount,
                                  cambio: p.exchange.exchange,
                                  flag_cambio_reverso:
                                      p.exchange.flagReverseExchange,
                              }
                            : undefined,
                    })),
                })
            else
                await createBuy({
                    flag_forma_pagamento: paymentType ?? 0,
                    id_fornecedor: buyInfo.supplier?.id ?? 0,
                    numero_fatura: buyInfo.bill,
                    produtos: buyInfo.products.map((p) => ({
                        id_produto: p.product?.id ?? 0,
                        preco_compra: p.price,
                        preco_venda: p.salePrice,
                        quantidade_produto: p.qnt,
                        porc_lucro: p.porcentGain,
                        exchange: p.exchange
                            ? {
                                  id_moeda_estrangeira: p.exchange.coin.id ?? 0,
                                  valor_moeda_estrangeira: p.exchange.amount,
                                  cambio: p.exchange.exchange,
                                  flag_cambio_reverso:
                                      p.exchange.flagReverseExchange,
                              }
                            : undefined,
                    })),
                    purchase_payment:
                        payment && payment.amountPayed
                            ? {
                                  id_metodo_pagamento:
                                      payment.paymentMethod?.id ?? 0,
                                  id_moeda: payment.paymentCoin?.id ?? 0,
                                  id_moeda_troco: payment.changeCoin?.id ?? 0,
                                  valor_pagamento: payment.amountPayed,
                                  comprovante_imagem: payment.proofImage,
                                  comprovante_texto: payment.proofText,
                                  flag_descontar_caixa: Number(
                                      payment.cashierDiscounted
                                  ),
                              }
                            : null,
                    id_filial_master: buyInfo.branch?.id,
                    frete: buyInfo.freight,
                    despacho: buyInfo.dispatch,
                    descarga: buyInfo.discharge,
                    total_manual: buyInfo.manualTotal,
                    data_consignacao: buyInfo.consignationDate
                        ? getDateInIsoFormat(buyInfo.consignationDate)
                        : null,
                })
            snack.success('Compra criada com sucesso!')
            navigate('/system/buy')
            setBuyInfo({ ...buyInfoDefault })
        } catch (error) {
            if (error instanceof CashierClosedBuyError)
                snack.error('O caixa está fechado!')
            else snack.connectionFail()
        }
        loading.hide()
    }

    const handleCreateNewLoadStock = async () => {
        loading.show()
        try {
            await loadStock({
                products: buyInfo.products.map((p) => ({
                    id_produto: p.product?.id ?? 0,
                    quantidade_produto: p.qnt,
                    quantidade_produto_negativo: p.negativeQnt ?? 0,
                })),
                id_filial_master: buyInfo.branch?.id,
            })
            snack.success('Produtos carregados com sucesso!')
            navigate('/system/product/load-stock')
            setBuyInfo({ ...buyInfoDefault })
        } catch (error) {
            if (error instanceof CashierClosedBuyError)
                snack.error('O caixa está fechado!')
            else snack.connectionFail()
        }
        loading.hide()
    }

    const resetContext = () => {
        setBuyInfo({ ...buyInfoDefault })
    }

    const editBuy = async (id: number) => {
        try {
            const buy = await getBuyById(id, coins)

            buy.products.forEach((p, i) => {
                p.subtotal = p.price * p.qnt
                p.id = i + 1
            })

            setBuyInfo(() => ({
                ...buy,
                total: getTotal(buy.products),
                totalIva: getTotalIva(buy.products),
                isEditing: true,
                id,
                productCount: buy.products.length,
            }))
        } catch (error) {
            console.log(error)
            snack.error('Compra não encontrada')
            throw new Error('Purchase not found')
        }
    }

    const handleChangeSelectedProducts = (products: ProductInBuy[]) => {
        setBuyInfo((prev) => ({
            ...prev,
            selectedProducts: products,
        }))
    }

    const handleCalculatePriceWithCost = () => {
        const totalCosts =
            buyInfo.freight + buyInfo.dispatch + buyInfo.discharge
        const newProducts = [...buyInfo.products]

        for (const product of newProducts) {
            const prop = product.subtotal / buyInfo.total
            const newCostToAdd = totalCosts * prop

            product.salePrice = product.price + newCostToAdd / product.qnt
        }

        setBuyInfo((prev) => ({
            ...prev,
            products: newProducts,
            total: getTotal(newProducts),
            totalIva: getTotalIva(newProducts),
        }))
    }

    return (
        <buyContext.Provider
            value={{
                buyInfo,
                setBuyInfo,
                handleChangeBill,
                handleAddNewProducts,
                handleRemoveProduct,
                handleChangeProductQnt,
                handleChangeProductNegativeQnt,
                handleChangeProductPrice,
                handleChangeProductSubtotal,
                handleChangeProductExchange,
                handleEditProduct,
                handleChangeSupplier,
                handleChangePayment,
                handleChangeBranch,
                validate,
                handleCreateNewBuy,
                handleChangeSelectedProducts,
                validateLoadStock,
                handleCreateNewLoadStock,
                resetContext,
                editBuy,
                handleCalculatePriceWithCost,
            }}
        >
            {children}
        </buyContext.Provider>
    )
}
